import React from "react";
import Link from "gatsby-link";

import { Jumbotron, Grid, Row, Col, Image, Button } from "react-bootstrap";
import AltHeader from "../../components/alt-header.js";
import Header from "../../components/header.js";
import UpNext from "../../components/up-next.js";
import Layout from "../../components/Layout";
import JobHeader from "../../components/job-header.js";

const ChristianVermaakPage = () => (
  <Layout>
    <JobHeader
      image={"/img/vermaak-header.jpg"}
      title="Christian Vermaak"
      subtitle="Website support and page re-designs"
      text="Web Design / Internship"
    />

    <Row className="show-grid side-padding intro">
      <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
        <h2>My Role</h2>
        <p>
          <em>
            Towards the end of my time at University undertook an internship at
            a Web Design agency called Mythicus. They had high end entertainment
            clients and I got to work on some amazing projects. One such project
            was a site for celebrity stylist Christian Vermaak. I worked on this
            project along side my manager who was great at giving me license to
            use my creativity. Coming from a traditional Graphic Design
            background, this internship was key in teaching me the skills to go
            in to web design.{" "}
          </em>
        </p>
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={12}>
        <Image src="/img/christian-vermaak/christian_services.jpg" />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={12}>
        <Image src="/img/christian-vermaak/christian_latest.jpg" />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={12}>
        <Image src="/img/christian-vermaak/christian_videos.jpg" />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={12}>
        <Image src="/img/christian-vermaak/vermaak_gallery.jpg" />
      </Col>
    </Row>

    <UpNext
      image1="/img/marionnaud-card2.jpg"
      title1="Marionnaud"
      text1="A European Health & Beauty brand"
      url1="/marionnaud/"
      image2="/img/perfumeshop-card.jpg"
      title2="The Perfume Shop"
      text2="A fragrance specialist based in the UK"
      url2="/perfume-shop/"
      image3="/img/spread-co-card.jpg"
      title3="Spread Co"
      text3="A financial spread betting company"
      url3="/spread-co/"
    />
  </Layout>
);

export default ChristianVermaakPage;
