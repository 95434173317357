import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap"


export const JobHeader = (props) => {

  const style = {
    padding: '250px 0',
    backgroundImage: `url(${props.image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  };


  return (
    <div className="cover-wrapper">
      <Row className="show-grid full-width">
        <Col className="zero-padding" xs={12}>
          <div className="cover-image" style={style} ></div>
        </Col>
      </Row>
      <Row className="show-grid">
        <Col className="zero-padding" xs={12} sm={10} md={10}>
          <div className="cover-card">
            <h1>{props.title}</h1>
            <h2>{props.subtitle}</h2>
            <p>{props.text}</p>
          </div>
        </Col>
      </Row>
    </div>

  )
}

export default JobHeader

